import * as React from 'react'

import badge_501c from '../images/501c3.png'
import badge_dailypay from '../images/dailypay.png'
import logo from '../images/logo.svg'

const Footer = () => {
  return (
    <footer className="bg-brand-800">
      <div className="py-8 px-4 sm:py-16 sm:px-20 sm:flex sm:flex-row sm:justify-between sm:items-start">
        <div className="text-white text-lg sm:my-8">
          <h3 className="text-brand-200 text-2xl font-bold mb-5">
            Main offices
          </h3>
          <p className="mb-6">
            260 Doughty Boulevard <br /> Inwood, NY 11096
          </p>
          <p className="mb-6">
            526 Beach 20th st. <br /> Far Rockaway, NY 11691
          </p>
          <p>
            <a
              className="inline-block underline decoration-2 decoration-brand-200 leading-6"
              href="mailto:info@rockawayhc.com">
              info@rockawayhc.com
            </a>{' '}
            <br />
            <a href="tel:(516)2398693">(516) 239-8693</a>
          </p>
        </div>
        <div className="flex flex-row mt-14 sm:mt-0">
          <img
            className="mr-6"
            src={badge_dailypay}
            alt="Daily Pay Available"
          />
          <img src={badge_501c} alt="Officially 501(c)(3) Approved" />
        </div>
      </div>
      <div className="bg-grey-900 flex flex-row justify-between sm:justify-start items-stretch px-4 py-4 sm:px-14 sm:py-6 mt-10">
        <div className="w-24 relative flex-shrink-0">
          <img
            className="absolute bottom-0 sm:-bottom-3 w-24 h-24 flex-shrink-0"
            src={logo}
            alt="Rockaway"
          />
        </div>
        <p className="text-grey-200 ml-6 text-sm leading-6">
          Copyright © 2021 Rockaway Home Care - All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
