import React from 'react'
import classnames from 'classnames'

const Button = ({ children, ...props }) => {
  const buttonClasses = classnames(
    ['border-2 bg-brand-500 text-white font-bold w-full px-3 rounded-lg h-14'],
    ['hover:bg-white hover:text-brand-500 active:bg-[#0057A814]'],
    ['disabled:bg-grey-200 disabled:text-white disabled:border-transparent'],
    [!props.full && 'md:max-w-[254px]', props.full && 'w-full'],
    [props.className]
  )

  return (
    <button {...props} className={buttonClasses}>
      {children}
    </button>
  )
}

export default Button
