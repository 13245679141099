import React, {
  Component,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import ReactDOM from 'react-dom'
// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== `undefined` ? document.getElementById('portal') : null

class Portal extends Component {
  el: any
  constructor(props) {
    super(props)
    // Use a ternary operator to make sure that the document object is defined
    this.el =
      typeof document !== `undefined` ? document.createElement('div') : null
    this.el.setAttribute('role', 'dialog')
    this.el.setAttribute('aria-modal', 'true')
  }

  componentDidMount = () => {
    portalRoot.appendChild(this.el)
  }

  componentWillUnmount = () => {
    portalRoot.removeChild(this.el)
  }

  render() {
    const { children } = this.props

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    } else {
      return null
    }
  }
}

export type ModalHandlers = {
  openModal: () => void
  closeModal: () => void
}

const Modal = forwardRef<ModalHandlers, { children: React.ReactNode }>(
  (props, ref) => {
    const [display, setDisplay] = useState(false)

    useImperativeHandle(ref, () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
      }
    })

    const handleOpen = () => {
      setDisplay(true)
    }

    const handleClose = () => {
      setDisplay(false)
    }

    useEffect(() => {
      const close = (e) => {
        if (e.keyCode === 27) {
          handleClose()
        }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    }, [])

    if (display) {
      return (
        <Portal>
          <div
            className="fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 z-50 bg-opacity-25 bg-dark"
            onClick={handleClose}>
            <div
              className="bg-white rounded-xl p-8 w-4/5 max-w-md"
              onClick={(e) => e.stopPropagation()}>
              {props.children}
            </div>
          </div>
        </Portal>
      )
    }

    return null
  }
)

export default Modal
