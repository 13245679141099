import React, { useEffect, useState } from 'react'

import logo from '../images/logo.svg'
import Button from './Button'

const Logo = () => {
  return (
    <img
      className="w-16 h-16 flex-shrink-0 md:w-20 md:h-20"
      src={logo}
      alt="Rockaway"
    />
  )
}

const scrollToEligibilityForm = () => {
  document
    .querySelector('#eligibilityForm>h3')
    .scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const ApplyNow = () => {
  return (
    <Button className="flex-shrink w-40" onClick={scrollToEligibilityForm}>
      Apply now
    </Button>
  )
}

const getEndOffset = (element) => {
  const rect = element?.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return rect!.top + rect.height + scrollTop
}

const Header = ({phoneNumber, phoneCta, ctaText}) => {
  const [showApply, setShowApply] = useState(false)

  useEffect(() => {
    const heightToHideFrom = getEndOffset(
      document.getElementById('eligibilityForm')
    )

    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

      if (winScroll > heightToHideFrom) {
        setShowApply(true)
      } else {
        setShowApply(false)
      }
    }
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [setShowApply])

  return (
    <header className="h-24 px-3 sm:px-14 bg-white flex flex-row items-center justify-between sticky top-0 z-10">
      <Logo />
      <p className="font-bold text-center text-xs sm:text-lg leading-5 mx-4 flex-grow">
        <span>{ctaText}</span>{' '}
        <span className="inline-block">{phoneCta}</span>{' '}
        <a
          className="inline-block underline decoration-2 decoration-brand-200"
          href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
      </p>
      {showApply && <ApplyNow />}
    </header>
  )
}

export default Header
